/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import Cabin from "./Cabin";
import TextContainer from "../Content/TextContainer";

import { CabinDetailsProps } from "../CabinDetails";

import { media, Breakpoint, themeColor } from "../../theme";

const ChooseCabinContent = styled.div``;

const ChooseCabinHeaderWrapper = styled.div`
  margin: 0 auto 40px;

  ${media(Breakpoint.TabletPlus)} {
    max-width: 1130px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 80px;
  }
`;

const ChooseCabinHeader = styled.h3`
  font-size: 36px;
  line-height: 48px;
  color: ${themeColor("grey700")};

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0 auto 20px;

  ${media(Breakpoint.TabletPlus)} {
    font-size: 46px;
    line-height: 56px;
    text-align: left;
  }
`;

const ChooseCabinIntro = styled(TextContainer)`
  max-width: 540px;
  text-align: center;
  margin: 0 auto;

  ${media(Breakpoint.TabletPlus)} {
    max-width: none;
    margin: 0;

    p {
      font-size: 18px;
      line-height: 34px;
      text-align: left;
    }
  }
`;

const Icon = styled.img`
  display: block;
  margin: 0 auto 20px;

  ${media(Breakpoint.TabletPlus)} {
    order: 2;
    margin: 0 0 0 40px;
  }
`;

const CabinsList = styled.div`
  display: grid;
  grid-gap: 40px;
  justify-content: center;

  ${media(Breakpoint.Tablet)} {
    grid-template-columns: repeat(2, 335px);
  }

  ${media(Breakpoint.TabletPlus)} {
    grid-template-columns: repeat(2, minmax(400px, 556px));
    grid-gap: 50px 20px;
  }
`;

interface ChooseCabinProps {
  className?: string;
  header: string;
  subheader: string;
  cabins: CabinDetailsProps[];
}

const ChooseCabin: React.FC<ChooseCabinProps> = ({ className, header, subheader, cabins }) => {
  return (
    <div className={className}>
      <ChooseCabinContent>
        <ChooseCabinHeaderWrapper>
          <Icon alt="logo" src={`/choose-cabin.svg`} width={215} height={156} />
          <div>
            <ChooseCabinHeader>{header}</ChooseCabinHeader>
            <ChooseCabinIntro>
              <p>{subheader}</p>
            </ChooseCabinIntro>
          </div>
        </ChooseCabinHeaderWrapper>
        <CabinsList>
          {cabins.map((cabin, idx) => (
            <Cabin key={idx} cabin={cabin} />
          ))}
        </CabinsList>
      </ChooseCabinContent>
    </div>
  );
};

export default styled(ChooseCabin)`
  position: relative;
  padding: 40px 20px;
  overflow: hidden;

  ${media(Breakpoint.Tablet)} {
    padding: 100px 40px;
  }
`;
