import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import { CabinDetailsProps } from "../CabinDetails";

import { media, Breakpoint, themeColor, alpha } from "../../theme";

const CabinNameContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => alpha(theme.color.grey700, 0.7)};
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

const CabinName = styled.h2`
  color: ${themeColor("white")};
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;

  ${media(Breakpoint.TabletPlus)} {
    font-size: 36px;
    line-height: 48px;
  }
`;

interface CabinThumbnailProps {
  className?: string;
  cabin: CabinDetailsProps;
}

const CabinThumbnail: React.FC<CabinThumbnailProps> = ({ className, cabin }) => {
  return (
    <div className={className}>
      {cabin.mainImageMobile.localFile && (
        <GatsbyImage
          image={cabin.mainImageMobile.localFile.childImageSharp?.gatsbyImageData}
          alt={cabin.name}
          objectPosition="top center"
        />
      )}
      {cabin.mainImage.localFile && (
        <GatsbyImage
          image={cabin.mainImage.localFile.childImageSharp?.gatsbyImageData}
          alt={cabin.name}
          objectFit="cover"
          objectPosition="top center"
        />
      )}
      <CabinNameContainer>
        <CabinName>{cabin.name}</CabinName>
      </CabinNameContainer>
    </div>
  );
};

export default styled(CabinThumbnail)`
  position: relative;
  width: 100%;
  max-width: 335px;
  height: 152px;

  ${media(Breakpoint.TabletPlus)} {
    max-width: 556px;
    height: 360px;
  }

  > div {
    &:first-of-type {
      height: 100%;

      ${media(Breakpoint.TabletPlus)} {
        display: none;
      }
    }

    &:nth-of-type(2) {
      display: none;

      ${media(Breakpoint.TabletPlus)} {
        display: block;
        height: 100%;
      }
    }
  }
`;
