/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { File, Maybe } from "../../../gatsby-types";

import Button from "../Button";
import ButtonLink from "../Button/ButtonLink";
import TextContainer from "../Content/TextContainer";

import { media, Breakpoint, themeColor } from "../../theme";

const imageCss = css`
  display: none;

  ${media(Breakpoint.TabletPlus)} {
    display: block;
    height: 100%;
    max-height: 592px;
  }
`;

const RetreatContent = styled.div``;

const RetreatHeaderWrapper = styled.div`
  margin: 0 auto 20px;
`;

const RetreatHeader = styled.h2`
  font-size: 36px;
  line-height: 48px;
  color: ${themeColor("white")};

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  ${media(Breakpoint.TabletPlus)} {
    text-align: left;
  }
`;

const RetreatText = styled(TextContainer)`
  color: ${themeColor("white")};
  text-align: center;

  ${media(Breakpoint.TabletPlus)} {
    text-align: left;
  }
`;

const Icon = styled.img`
  display: block;
  margin: 0 auto 20px;

  ${media(Breakpoint.TabletPlus)} {
    margin: 0 0 40px;
  }
`;

const RetreatInner = styled.div`
  ${ButtonLink} {
    display: block;
    width: 210px;
    margin: 40px auto 0;

    ${media(Breakpoint.TabletPlus)} {
      margin: 40px 0 0;
    }

    ${Button} {
      width: 100%;
    }
  }
`;

interface RetreatProps {
  className?: string;
  header: string;
  content: string;
  buttonLabel: string;
  image?: Maybe<File>;
}

const Retreat: React.FC<RetreatProps> = ({ className, header, content, buttonLabel, image }) => {
  return (
    <div className={className}>
      <RetreatContent>
        <RetreatHeaderWrapper>
          <Icon alt="logo" src={`/retreat.svg`} width={102} height={61} />
          <RetreatHeader>{header}</RetreatHeader>
        </RetreatHeaderWrapper>
        <RetreatInner>
          <RetreatText dangerouslySetInnerHTML={{ __html: content }} />
          <ButtonLink to="/retreats/">
            <Button variant="dark">{buttonLabel}</Button>
          </ButtonLink>
        </RetreatInner>
      </RetreatContent>
      {image && <GatsbyImage image={image.childImageSharp?.gatsbyImageData} alt={header} css={imageCss} />}
    </div>
  );
};

export default styled(Retreat)`
  position: relative;
  padding: 40px 20px;
  overflow: hidden;
  background: ${themeColor("grey700")};

  ${media(Breakpoint.Tablet)} {
    padding: 40px;
  }

  ${media(Breakpoint.TabletPlus)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 0;
  }

  ${media(Breakpoint.Desktop)} {
    height: 592px;
    grid-gap: 135px;
  }

  ${RetreatContent} {
    padding: 40px;

    ${media(Breakpoint.Desktop)} {
      max-width: 595px;
      padding: 0 60px 0 95px;
      margin: 0 0 0 auto;
    }
  }
`;
