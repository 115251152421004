/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import TextContainer from "../Content/TextContainer";

import { media, Breakpoint, themeColor } from "../../theme";

const Icon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 174px;
  height: 256px;
  margin: auto;
`;

const LeadHomepageContent = styled.div`
  padding: 40px 20px;
`;

const LeadHomepageText = styled(TextContainer)`
  p {
    ${media(Breakpoint.Tablet)} {
      font-size: 18px;
      line-height: 34px;
    }

    ${media(Breakpoint.Desktop)} {
      text-align: center;
    }
  }
`;

interface LeadHomepageProps {
  className?: string;
  content: string;
}

const LeadHomepage: React.FC<LeadHomepageProps> = ({ className, content }) => {
  return (
    <div className={className}>
      <LeadHomepageContent>
        <Icon alt="logo" src={`/tree-decor.svg`} />
        <LeadHomepageText dangerouslySetInnerHTML={{ __html: content }} />
      </LeadHomepageContent>
    </div>
  );
};

export default styled(LeadHomepage)`
  display: flex;
  position: relative;
  background: ${themeColor("grey100")};
  min-height: 336px;
  align-items: center;

  ${media(Breakpoint.Tablet)} {
    padding: 40px;
  }

  ${media(Breakpoint.TabletPlus)} {
    padding: 100px 40px;
  }
`;
