import styled from "@emotion/styled";
import React from "react";

import Button from "../Button";
import ButtonAnchor from "../Button/ButtonAnchor";
import CabinThumbnail from "./CabinThumbnail";
import PlainLink from "./../PlainLink";

import { CabinDetailsProps } from "../CabinDetails";

import { media, Breakpoint, themeColor } from "../../theme";
import { slugify } from "../../utils";

const CabinDescription = styled.div`
  p {
    font-size: 12px;
    line-height: 24px;
    color: ${themeColor("black")};
  }
`;

const CabinComingSoon = styled.p`
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2em;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin: 0;

  ${media(Breakpoint.TabletPlus)} {
    margin-top: 60px;
  }
`;

const DetailsButtons = styled.div`
  display: grid;
  grid-gap: 32px;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 122px;
  width: 100%;
  margin-top: 40px;

  ${media(Breakpoint.TabletPlus)} {
    grid-template-columns: 205px 1fr;
    grid-gap: 60px;
    height: 60px;
    align-items: center;
  }

  ${ButtonAnchor} {
    display: block;
    width: 205px;

    ${Button} {
      width: 100%;
    }
  }

  ${PlainLink} {
    margin: 0 auto;

    ${media(Breakpoint.TabletPlus)} {
      margin: 0 auto 0 0;
    }
  }
`;

interface CabinProps {
  className?: string;
  cabin: CabinDetailsProps;
}

const Cabin: React.FC<CabinProps> = ({ className, cabin }) => {
  let detailsElement: JSX.Element;

  if (cabin.comingSoon) {
    detailsElement = <CabinComingSoon>Coming Soon</CabinComingSoon>;
  } else {
    detailsElement = (
      <DetailsButtons>
        <ButtonAnchor href={cabin.bookingUrl} target="_blank" rel="noopener noreferrer">
          <Button variant="light">Book Now</Button>
        </ButtonAnchor>
        <PlainLink variant="light" to={`/cabins#${slugify(cabin.name)}`}>
          Learn More
        </PlainLink>
      </DetailsButtons>
    );
  }

  return (
    <div className={className}>
      <CabinThumbnail cabin={cabin} />
      {cabin.description && <CabinDescription dangerouslySetInnerHTML={{ __html: cabin.description }} />}
      {detailsElement}
    </div>
  );
};

export default styled(Cabin)`
  position: relative;
  background: ${themeColor("white")};
  width: 100%;
  max-width: 335px;
  padding-bottom: 162px;

  ${media(Breakpoint.TabletPlus)} {
    max-width: 556px;
    padding-bottom: 100px;
  }

  ${CabinThumbnail} {
    margin: 0 auto 20px;
  }
`;
