import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import { media, Breakpoint, themeColor } from "../../theme";

const MAX_HEIGHT_MOBILE = "300px";
const MAX_HEIGHT_TABLETUP = "425px";

const BackgroundContainer = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-area: 1/1;
  align-items: center;
  justify-self: center;
  max-width: 1360px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(13, 15, 15, 0) 0%, rgba(13, 15, 15, 0.5) 100%);
  }

  > div {
    height: 100%;

    &:first-of-type {
      ${media(Breakpoint.Tablet)} {
        display: none;
      }
    }

    &:last-of-type {
      display: none;

      ${media(Breakpoint.Tablet)} {
        display: block;
      }
    }
  }
`;

const CTAContainer = styled.div`
  display: flex;
  grid-area: 1/1;
  flex-direction: column;
  position: relative;
  place-items: center;
  align-self: center;
  padding: 0 20px;

  p {
    max-width: 670px;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: ${themeColor("white")};
    margin: 0 0 40px;

    ${media(Breakpoint.Tablet)} {
      font-size: 18px;
      line-height: 34px;
    }
  }
`;

const Icon = styled.img`
  display: block;
  margin: 40px auto 20px;

  ${media(Breakpoint.Tablet)} {
    margin: 40px auto;
  }
`;

const Title = styled.h3`
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${themeColor("white")};

  ${media(Breakpoint.Tablet)} {
    font-size: 46px;
    line-height: 56px;
  }
`;

interface ShinrinYokuProps {
  className?: string;
  header: string;
  content: string;
}

const ShinrinYoku: React.FC<ShinrinYokuProps> = ({ className, header, content }) => {
  return (
    <div className={className}>
      <BackgroundContainer>
        <StaticImage
          src="../../assets/images/cta_shinrin_yoku_mobile.jpg"
          alt="CTA"
          layout="fullWidth"
          objectFit="cover"
          css={{ [media(Breakpoint.Mobile)]: { maxHeight: MAX_HEIGHT_MOBILE } }}
        />
        <StaticImage
          src="../../assets/images/cta_shinrin_yoku_tabletUp.jpg"
          alt="CTA"
          layout="fullWidth"
          objectFit="cover"
          css={{ maxHeight: MAX_HEIGHT_TABLETUP }}
        />
      </BackgroundContainer>
      <CTAContainer>
        <Icon alt="logo" src={`/shinrin-yoku.svg`} width={100} height={32} />
        <Title css={{ margin: "0 0 20px" }}>{header}</Title>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </CTAContainer>
    </div>
  );
};

export default styled(ShinrinYoku)`
  position: relative;
  display: grid;

  ${media(Breakpoint.Mobile)} {
    max-height: ${MAX_HEIGHT_MOBILE};
  }

  ${media(Breakpoint.Tablet)} {
    max-height: ${MAX_HEIGHT_TABLETUP};
  }
`;
