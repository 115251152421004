/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { File, Maybe } from "../../../gatsby-types";

import TextContainer from "../Content/TextContainer";

import { media, Breakpoint, themeColor } from "../../theme";

const imageCss = css`
  display: none;

  ${media(Breakpoint.TabletPlus)} {
    display: block;
    height: 100%;
    max-height: 747px;
  }
`;

const PropertyLocationContent = styled.div``;

const PropertyLocationHeaderWrapper = styled.div`
  margin: 0 auto 20px;

  ${media(Breakpoint.TabletPlus)} {
    margin: 0 auto 40px;
  }
`;

const PropertyLocationHeader = styled.h3`
  font-size: 36px;
  line-height: 48px;
  color: ${themeColor("grey700")};

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  ${media(Breakpoint.TabletPlus)} {
    text-align: left;
  }
`;

const Icon = styled.img`
  display: block;
  margin: 0 auto 20px;

  ${media(Breakpoint.TabletPlus)} {
    margin: 0 0 40px;
  }
`;

const PropertyLocationInner = styled.div``;

interface PropertyLocationProps {
  className?: string;
  header: string;
  content: string;
  image?: Maybe<File>;
}

const PropertyLocation: React.FC<PropertyLocationProps> = ({ className, header, content, image }) => {
  return (
    <div className={className}>
      {image && <GatsbyImage image={image.childImageSharp?.gatsbyImageData} alt={header} css={imageCss} />}
      <PropertyLocationContent>
        <PropertyLocationHeaderWrapper>
          <Icon alt="logo" src={`/property.svg`} width={102} height={61} />
          <PropertyLocationHeader dangerouslySetInnerHTML={{ __html: header }} />
        </PropertyLocationHeaderWrapper>
        <PropertyLocationInner>
          <TextContainer dangerouslySetInnerHTML={{ __html: content }} />
        </PropertyLocationInner>
      </PropertyLocationContent>
    </div>
  );
};

export default styled(PropertyLocation)`
  position: relative;
  padding: 40px 20px;
  overflow: hidden;

  ${media(Breakpoint.Tablet)} {
    padding: 40px;
  }

  ${media(Breakpoint.TabletPlus)} {
    display: grid;
    grid-template-columns: 43% auto;
    align-items: center;
    padding: 0;
  }

  ${media(Breakpoint.Desktop)} {
    height: 747px;
    grid-gap: 135px;
  }

  ${PropertyLocationContent} {
    padding: 40px;

    ${media(Breakpoint.Desktop)} {
      max-width: 635px;
      padding: 0 85px 0 0;
    }
  }
`;
