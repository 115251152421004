import styled from "@emotion/styled";
import React from "react";

import TextContainer from "../Content/TextContainer";

import { themeColor } from "../../theme";

const GuestNoteQuote = styled.img`
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
`;

const GuestNoteText = styled(TextContainer)`
  position: relative;
  z-index: 2;
`;

interface GuestNoteProps {
  className?: string;
  text: string;
}

const GuestNote: React.FC<GuestNoteProps> = ({ className, text }) => {
  return (
    <div className={className}>
      <GuestNoteQuote alt="quote" src={`/guest-note-quote.svg`} />
      <GuestNoteText dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default styled(GuestNote)`
  position: relative;
  background: ${themeColor("white")};
  padding: 25px 20px 40px;
`;
