/** @jsx jsx */
import { jsx } from "@emotion/react";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";

import React from "react";
import { WpMediaItem, Query, Maybe, WpPage } from "../../gatsby-types";

import { CabinDetailsProps } from "../components/CabinDetails";
import ChooseCabin from "../components/ChooseCabin";
import GuestNotes from "../components/GuestNotes";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import LeadHomepage from "../components/LeadHomepage";
import PropertyLocation from "../components/PropertyLocation";
import Retreat from "../components/Retreat";
import SectionGrey from "../components/SectionGrey";
import ShinrinYoku from "../components/ShinrinYoku";

import { acfCabinsProps } from "./cabins";

import { media, Breakpoint } from "../theme";

type PropsType = {
  data: Query & { cabinsPage: Maybe<WpPage> };
};

type acfMainProps = {
  heroHeader: string;
  heroSubheader: string;
  heroBtnLabel: string;
  heroBtnLink: string;
  heroOutro: string;
  cabinsHeader: string;
  cabinsSubheader: string;
  retreatButtonLabel: string;
  retreatContent: string;
  retreatHeader: string;
  retreatImage: WpMediaItem;
  locationContent: string;
  locationHeader: string;
  locationImage: WpMediaItem;
  guests1: string;
  guests2: string;
  shinrinYokuContent: string;
  shinrinYokuHeader: string;
};

const Main: React.FC<PropsType> = (props) => {
  if (!props.data.wpPage || !props.data.wpPage.acfMain) {
    return null;
  }

  let cabinsObj: CabinDetailsProps[] = [];
  if (props.data.cabinsPage && props.data.cabinsPage.acfCabins) {
    const { cabins } = props.data.cabinsPage.acfCabins as acfCabinsProps;
    cabinsObj = cabins;
  }

  const {
    heroHeader,
    heroSubheader,
    heroBtnLabel,
    heroBtnLink,
    heroOutro,
    cabinsHeader,
    cabinsSubheader,
    retreatHeader,
    retreatContent,
    retreatButtonLabel,
    retreatImage,
    locationHeader,
    locationContent,
    locationImage,
    guests1,
    guests2,
    shinrinYokuHeader,
    shinrinYokuContent,
  } = props.data.wpPage.acfMain as acfMainProps;

  return (
    <Layout>
      <Seo post={props.data.wpPage} />
      <Hero
        page="homepage"
        header={heroHeader}
        subHeader={heroSubheader}
        buttonHref={heroBtnLink}
        buttonLabel={heroBtnLabel}
      />
      <LeadHomepage content={heroOutro} />
      <ChooseCabin header={cabinsHeader} subheader={cabinsSubheader} cabins={cabinsObj} />
      <Retreat
        header={retreatHeader}
        content={retreatContent}
        buttonLabel={retreatButtonLabel}
        image={retreatImage.localFile}
      />
      <PropertyLocation header={locationHeader} content={locationContent} image={locationImage.localFile} />
      <SectionGrey>
        <GuestNotes
          notes={[guests1, guests2]}
          css={{
            padding: "40px 0",
            maxWidth: "1150px",
            margin: "0 auto",
            [media(Breakpoint.TabletPlus)]: { padding: "100px 0" },
          }}
        />
        <ShinrinYoku header={shinrinYokuHeader} content={shinrinYokuContent} />
      </SectionGrey>
    </Layout>
  );
};

export const pageQuery = graphql`
  query MainPage($wpId: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    wpPage(id: { eq: $wpId }) {
      id
      title
      nodeType
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfMain {
        heroHeader
        heroSubheader
        heroBtnLabel
        heroBtnLink
        heroOutro
        cabinsHeader
        cabinsSubheader
        retreatButtonLabel
        retreatContent
        retreatHeader
        retreatImage {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1420, height: 1184)
            }
          }
        }
        locationContent
        locationHeader
        locationImage {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1190, height: 1548)
            }
          }
        }
        guests1
        guests2
        shinrinYokuContent
        shinrinYokuHeader
      }
    }
    cabinsPage: wpPage(slug: { eq: "cabins" }) {
      acfCabins {
        cabins {
          bath
          bedroom
          bookingUrl
          comingSoon
          description
          guests
          name
          petFriendly
          startingAt
          mainImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1110, height: 720)
              }
            }
          }
          galleryImage: mainImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 880, height: 600)
              }
            }
          }
          mainImageMobile: mainImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 670, height: 304)
              }
            }
          }
        }
      }
    }
  }
`;

export default Main;
