/** @jsx jsx */
import styled from "@emotion/styled";

export default styled.div`
  p {
    font-size: 14px;
    line-height: 26px;

    &:not(:last-of-type) {
      margin: 0 0 15px;
    }
  }
`;
