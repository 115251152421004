import styled from "@emotion/styled";
import { Link } from "gatsby";

export default styled(Link)`
  && {
    display: inline-block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`;
