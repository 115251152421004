import styled from "@emotion/styled";
import React from "react";

import GuestNote from "./GuestNote";

import { media, Breakpoint } from "../../theme";

const GuestNotesHeaderWrapper = styled.div``;

const GuestNotesHeader = styled.h3`
  font-size: 36px;
  line-height: 48px;

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  margin: 0 auto 40px;

  ${media(Breakpoint.TabletPlus)} {
    font-size: 46px;
    line-height: 56px;
    text-align: left;
  }
`;

const Icon = styled.img`
  display: block;
  margin: 0 auto 20px;

  ${media(Breakpoint.TabletPlus)} {
    margin: 0 0 45px;
  }
`;

const GuestNotesInner = styled.div`
  display: grid;
  grid-gap: 40px;

  ${media(Breakpoint.Tablet)} {
    display: flex;
    height: 265px;
  }

  ${media(Breakpoint.Desktop)} {
    grid-gap: 95px;
    height: 250px;
  }

  ${GuestNote} {
    min-width: 250px;
  }
`;

interface GuestNotesProps {
  className?: string;
  notes: string[];
}

const GuestNotes: React.FC<GuestNotesProps> = ({ className, notes }) => {
  return (
    <div className={className}>
      <GuestNotesHeaderWrapper>
        <Icon alt="logo" src={`/guest-notes.svg`} width={136} height={61} />
        <GuestNotesHeader>Guest Notes</GuestNotesHeader>
      </GuestNotesHeaderWrapper>
      <GuestNotesInner>
        {notes.map((guestNote, idx) => (
          <GuestNote key={idx} text={guestNote} />
        ))}
      </GuestNotesInner>
    </div>
  );
};

export default styled(GuestNotes)`
  position: relative;

  ${media(Breakpoint.TabletPlus)} {
    display: grid;
    flex-direction: row;
    grid-template-columns: 240px fit-content(830px);
    justify-content: space-between;
  }
`;
